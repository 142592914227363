import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/optiven-logo-full.png";

const ReferralForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [projectId, setProjectId] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [projectDetails, setProjectDetails] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
    agreedToTerms: false,
  });
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState("");

  useEffect(() => {
    // Get query parameters
    const projectIdParam = queryParams.get("projectId");
    const referralCodeParam = queryParams.get("referralCode");

    setProjectId(projectIdParam || "");
    setReferralCode(referralCodeParam || "");

    // Fetch project details
    if (projectIdParam) {
      fetchProjectDetails(projectIdParam);
    }
  }, [location.search]);

  const fetchProjectDetails = async (projectId) => {
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/sites/${projectId}`
      ); // Adjust the API endpoint as necessary
      setProjectDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch project details:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.phoneNumber.trim())
      newErrors.phoneNumber = "Phone Number is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.agreedToTerms)
      newErrors.agreedToTerms = "You must agree to the terms and conditions";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      // Prepare data to send to the server
      const submissionData = {
        ...formData,
        referralCode,
        projectId,
      };

      // Send data to the server (adjust the endpoint as necessary)
      await axios.post(
        "https://workspace.optiven.co.ke/api/referrals",
        submissionData
      );

      setSubmissionStatus(
        "Thank you for your interest! We will contact you shortly."
      );
      setFormData({
        name: "",
        phoneNumber: "",
        email: "",
        message: "",
        agreedToTerms: false,
      });
    } catch (error) {
      console.error("Failed to submit referral:", error);
      setSubmissionStatus("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="navbar bg-primary">
        <Link className="btn btn-ghost normal-case text-xl" to="#">
          <img src={logo} alt="logo" className="w-40" />
        </Link>
      </div>
      <div className="container mx-auto p-4 mt-6">
        <h1 className="text-2xl font-bold">
          {projectDetails ? projectDetails.name : "Project Details"}
        </h1>
        {projectDetails && (
          <div className="project-details">
            <img
              src={projectDetails.banner}
              className="my-2"
              alt={projectDetails.name}
            />
            <p>{projectDetails.description}</p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <h2 className="mt-2 font-bold">
            🎉Get 1% off this project for cash purchases a limited time!🎉
          </h2>

          <div className="flex flex-col">
            <label htmlFor="name" className="label font-bold">
              Name*
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleInputChange}
              className="input input-bordered"
            />
            {errors.name && <span className="error-text">{errors.name}</span>}
          </div>

          <div className="flex flex-col">
            <label htmlFor="phoneNumber" className="label font-bold">
              Phone Number*
            </label>
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="input input-bordered"
            />
            {errors.phoneNumber && (
              <span className="error-text">{errors.phoneNumber}</span>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="email" className="label font-bold">
              Email*
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              className="input input-bordered"
            />
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>

          <div className="flex flex-col">
            <label htmlFor="message" className="label font-bold">
              Message (Optional)
            </label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleInputChange}
              className="textarea textarea-bordered h-24"
            ></textarea>
          </div>

          <div className="my-2 flex items-center">
            <input
              type="checkbox"
              name="agreedToTerms"
              id="agreedToTerms"
              checked={formData.agreedToTerms}
              onChange={handleInputChange}
              className="checkbox"
            />
            <label htmlFor="agreedToTerms" className="font-bold ml-2">
              I have read the
              <a
                href="https://www.optiven.co.ke/privacy-policy/"
                className="underline"
              >
                privacy policy
              </a>
              *
            </label>
            {errors.agreedToTerms && (
              <span className="error-text">{errors.agreedToTerms}</span>
            )}
          </div>

          <button type="submit" className="btn btn-outline w-full">
            Submit
          </button>

          {submissionStatus && (
            <p className="submission-status">{submissionStatus}</p>
          )}
        </form>
      </div>
    </>
  );
};

export default ReferralForm;
