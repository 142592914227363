import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../components/sidebar/Sidebar";

const SurveyForm = () => {
  const [formData, setFormData] = useState({
    customer_name: "",
    plot_no: "",
    primary_phone: "",
    alternative_phone: "",
    email: "",
    service_feedback: "",
    service_rating: 3,
    server_name: "",
    server_rating: 3,
    received_title: "", // Yes/No
    improvement_areas: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation Example: Check Required Fields
    if (!formData.customer_name || !formData.plot_no || !formData.primary_phone) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/customer-survey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Survey submitted successfully!");
        setFormData({
          customer_name: "",
          plot_no: "",
          primary_phone: "",
          alternative_phone: "",
          email: "",
          service_feedback: "",
          service_rating: 3,
          server_name: "",
          server_rating: 3,
          received_title: "",
          improvement_areas: "",
        });
      } else {
        toast.error("Failed to submit survey. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Sidebar>
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      {/* Toast Notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      <div className="bg-white shadow-lg rounded-lg w-full max-w-2xl p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">Customer Survey</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Customer Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Customer Name</label>
            <input
              type="text"
              name="customer_name"
              value={formData.customer_name}
              onChange={handleChange}
              required
              className="input input-bordered w-full"
              placeholder="Enter customer name"
            />
          </div>

          {/* Plot Number */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Plot No</label>
            <input
              type="text"
              name="plot_no"
              value={formData.plot_no}
              onChange={handleChange}
              required
              className="input input-bordered w-full"
              placeholder="Enter plot number"
            />
          </div>

          {/* Primary Phone */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Primary Phone (with country code)</label>
            <input
              type="text"
              name="primary_phone"
              value={formData.primary_phone}
              onChange={handleChange}
              required
              className="input input-bordered w-full"
              placeholder="+254123456789"
            />
          </div>

          {/* Alternative Phone */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Alternative Phone</label>
            <input
              type="text"
              name="alternative_phone"
              value={formData.alternative_phone}
              onChange={handleChange}
              className="input input-bordered w-full"
              placeholder="Optional"
            />
          </div>

          {/* Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Preferred Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="input input-bordered w-full"
              placeholder="Enter your email"
            />
          </div>

          {/* Service Feedback */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kindly let us know how was the service throughout the process</label>
            <textarea
              name="service_feedback"
              value={formData.service_feedback}
              onChange={handleChange}
              required
              className="textarea textarea-bordered w-full"
              placeholder="Share your experience"
            ></textarea>
          </div>

          {/* Service Rating */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">How would you rate our services? (1-5)</label>
            <input
              type="range"
              name="service_rating"
              min="1"
              max="5"
              value={formData.service_rating}
              onChange={handleChange}
              className="range"
            />
            <div className="text-center mt-1">Rating: {formData.service_rating}</div>
          </div>

          {/* Server Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Do you remember who served you?</label>
            <input
              type="text"
              name="server_name"
              value={formData.server_name}
              onChange={handleChange}
              required
              className="input input-bordered w-full"
              placeholder="Enter server name"
            />
          </div>

          {/* Server Rating */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">How would you rate their service? (1-5)</label>
            <input
              type="range"
              name="server_rating"
              min="1"
              max="5"
              value={formData.server_rating}
              onChange={handleChange}
              className="range"
            />
            <div className="text-center mt-1">Rating: {formData.server_rating}</div>
          </div>

          {/* Received Title */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Did you receive your title deed on the promised time?</span>
            </label>
            <div className="flex items-center gap-4">
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="received_title"
                  value="Yes"
                  checked={formData.received_title === "Yes"}
                  onChange={handleChange}
                  className="radio"
                />
                <span>Yes</span>
              </label>
              <label className="flex items-center gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="received_title"
                  value="No"
                  checked={formData.received_title === "No"}
                  onChange={handleChange}
                  className="radio"
                />
                <span>No</span>
              </label>
            </div>
          </div>

          {/* Improvement Areas */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Let us know areas we can improve</label>
            <textarea
              name="improvement_areas"
              value={formData.improvement_areas}
              onChange={handleChange}
              className="textarea textarea-bordered w-full"
              placeholder="Share your suggestions"
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button type="submit" className="btn btn-primary w-full">Submit Survey</button>
          </div>
        </form>
      </div>
    </div>
    </Sidebar>
  );
};

export default SurveyForm;
