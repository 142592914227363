import React from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";

const surveys = [
  {
    id: 1,
    name: "Customer Feedback Survey",
    description: "Gather insights on customer satisfaction and Improvements that need to be implemented.",
    route: "/customer-survey",
  },
  {
    id: 2,
    name: "Employee Systems Survey",
    description: "Evaluate team engagement and work culture improvements.",
    route: "/system-survey",
  },
];

const SurveyDashboard = () => {
  const navigate = useNavigate();

  return (
    <Sidebar>
    <div className="min-h-screen bg-base-200 py-12 px-4">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-4xl font-extrabold text-center text-primary mb-12">
          Survey Dashboard
        </h1>
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2">
          {surveys.map((survey) => (
            <div key={survey.id} className="card bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-300">
              <div className="card-body">
                <h2 className="card-title text-primary">{survey.name}</h2>
                <p className="text-gray-600 mb-6">{survey.description}</p>
                <div className="card-actions justify-end">
                  <button
                    onClick={() => navigate(survey.route)}
                    className="btn btn-primary"
                  >
                    View Survey
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </Sidebar>
  );
};

export default SurveyDashboard;
