import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import Sidebar from "../components/sidebar/Sidebar";

const ViewCustomerSurvey = () => {
  const [responses, setResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    fetchResponses();
  }, []);

  const fetchResponses = async () => {
    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/customer-survey");
      const data = await response.json();
      setResponses(data);
    } catch (error) {
      console.error("Error fetching survey responses:", error);
      toast.error("Failed to fetch survey responses.");
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Customer Survey Responses", 14, 10);

    const tableData = responses.map((response) => [
      response.customer_name,
      response.plot_no,
      response.service_feedback,
      response.service_rating,
      response.server_name,
      response.server_rating,
      response.received_title,
      response.improvement_areas,
    ]);

    doc.autoTable({
      head: [
        [
          "Customer Name",
          "Plot No",
          "Service Feedback",
          "Service Rating",
          "Server Name",
          "Server Rating",
          "Received Title",
          "Improvement Areas",
        ],
      ],
      body: tableData,
    });

    doc.save("survey_responses.pdf");
    toast.success("PDF downloaded successfully!");
  };

  const renderDetails = (response) => (
    <div className="p-4 bg-gray-100 rounded-lg space-y-4">
      <h3 className="text-lg font-semibold">Survey Details</h3>
      <p>
        <strong>Customer Name:</strong> {response.customer_name}
      </p>
      <p>
        <strong>Plot No:</strong> {response.plot_no}
      </p>
      <p>
        <strong>Service Feedback:</strong> {response.service_feedback}
      </p>
      <p>
        <strong>Service Rating:</strong> {response.service_rating}
      </p>
      <p>
        <strong>Server Name:</strong> {response.server_name}
      </p>
      <p>
        <strong>Server Rating:</strong> {response.server_rating}
      </p>
      <p>
        <strong>Received Title:</strong> {response.received_title}
      </p>
      <p>
        <strong>Improvement Areas:</strong> {response.improvement_areas}
      </p>
    </div>
  );

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = responses.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Sidebar>
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-4">Customer Surveys</h1>

      <div className="overflow-x-auto mb-6">
        <table className="table w-full">
          <thead>
            <tr>
              <th>#</th>
              <th>Customer Name</th>
              <th>Plot No</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((response, index) => (
              <tr key={response.id}>
                <td>{index + 1}</td>
                <td>{response.customer_name}</td>
                <td>{response.plot_no}</td>
                <td>
                  <button
                    onClick={() => setSelectedResponse(response)}
                    className="btn btn-sm btn-secondary"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedResponse && (
        <div className="mt-6">
          {renderDetails(selectedResponse)}
          <button
            onClick={() => setSelectedResponse(null)}
            className="btn btn-error mt-4"
          >
            Close
          </button>
        </div>
      )}

      {/* Pagination */}
      <div className="flex justify-center mt-6 space-x-2">
        {Array.from({ length: Math.ceil(responses.length / entriesPerPage) }).map((_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`btn btn-sm ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Download PDF Button */}
      <div className="flex justify-center mt-6">
        <button
          onClick={handleDownloadPDF}
          className="btn btn-primary"
        >
          Download PDF
        </button>
      </div>
    </div>
    </Sidebar>
  );
};

export default ViewCustomerSurvey;
