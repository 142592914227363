import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import {
  User,
  CreditCard,
  Phone,
  Globe,
  PhoneCall,
  Mail as MailIcon,
  ArrowLeft,
} from "react-feather";
import { useSelector } from "react-redux";
import Sidebar from "../components/Sidebar";

// Function to determine the loyalty tier based on total_paid
const getTier = (totalPaid) => {
  if (totalPaid > 20000000) return "Platinum";
  if (totalPaid > 10000000) return "Gold";
  if (totalPaid > 5000000) return "Silver";
  if (totalPaid > 1000000) return "Bronze";
  return "Sapphire";
};

// Component to display the tier label
const TierLabel = ({ tier }) => {
  let colorClass;
  switch (tier) {
    case "Platinum":
      colorClass = "bg-gray-300 text-white";
      break;
    case "Gold":
      colorClass = "bg-yellow-500 text-white";
      break;
    case "Silver":
      colorClass = "bg-gray-500 text-white";
      break;
    case "Bronze":
      colorClass = "bg-orange-700 text-white";
      break;
    case "Sapphire":
      colorClass = "bg-indigo-500 text-white";
      break;
    default:
      colorClass = "bg-gray-300 text-black";
  }

  return (
    <span
      className={`px-3 py-1 rounded-full text-sm font-semibold ${colorClass}`}
    >
      {tier}
    </span>
  );
};

const CustomerDetail = () => {
  const { customer_id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Get department from Redux state to determine access
  const department = useSelector((state) => state.user.user.department);

  // Fetch customer data from the API
  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/loyalty/${customer_id}`
        );
        setCustomer(response.data.customer);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomer();
  }, [customer_id]);

  // Format phone number for tel link
  const formatPhoneLink = (phone) => {
    const cleaned = phone.replace(/\D/g, "");
    return `tel:${cleaned}`;
  };

  // Format email for mailto link
  const formatEmailLink = (email) => {
    return `mailto:${email}`;
  };

  // Format currency in KES
  const formatCurrency = (amount) => {
    const numericAmount = Number(amount);
    if (isNaN(numericAmount)) {
      return "KES 0.00";
    }
    return new Intl.NumberFormat("en-KE", {
      style: "currency",
      currency: "KES",
      minimumFractionDigits: 2,
    }).format(numericAmount);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-green-400 to-blue-500">
        <TailSpin height="80" width="80" color="#ffffff" visible />
      </div>
    );
  }

  if (error || !customer) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-green-400 to-blue-500">
        <h2 className="text-2xl font-semibold text-white">
          Customer not found
        </h2>
      </div>
    );
  }

  const tier = getTier(customer.total_paid);

  // Obscure certain fields if not in ICT SYSTEMS department
  const canViewSensitive = department === "ICT SYSTEMS";

  const displayedNationalId = canViewSensitive
    ? customer.national_id || "N/A"
    : "******";
  const displayedPassportNo = canViewSensitive
    ? customer.passport_no || "N/A"
    : "******";
  const displayedTotalSpent = canViewSensitive
    ? formatCurrency(customer.total_paid)
    : "******";
  const displayedCustomerNo = canViewSensitive
    ? customer.customer_no || "N/A"
    : "******";

  return (
    <Sidebar>
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-8">
          {/* Back Button */}
          <Link
            to="/view-customers"
            className="flex items-center text-blue-500 mb-6"
          >
            <ArrowLeft className="mr-2" /> Back to Customers
          </Link>

          {/* Customer Header */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
            <div className="flex items-center space-x-4">
              <div className="w-24 h-24 rounded-full bg-blue-100 flex items-center justify-center">
                <User className="text-blue-500" size={48} />
              </div>
              <div>
                <h1 className="text-3xl mb-2 font-extrabold text-gray-800">
                  {customer.customer_name}
                </h1>
                <TierLabel tier={tier} />
              </div>
            </div>
            <div className="mt-4 sm:mt-0">
              <div className="flex space-x-4">
                {customer.phone && (
                  <a
                    href={formatPhoneLink(customer.phone)}
                    className="flex items-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                  >
                    <PhoneCall className="mr-2" />
                    Call
                  </a>
                )}
                {customer.primary_email && (
                  <a
                    href={formatEmailLink(customer.primary_email)}
                    className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                  >
                    <MailIcon className="mr-2" />
                    Email
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Customer Details */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="flex items-center">
              <CreditCard className="text-purple-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">National ID</h3>
                <p className="text-gray-700">{displayedNationalId}</p>
              </div>
            </div>
            <div className="flex items-center">
              <CreditCard className="text-pink-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Passport No</h3>
                <p className="text-gray-700">{displayedPassportNo}</p>
              </div>
            </div>
            <div className="flex items-center">
              <User className="text-green-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Gender</h3>
                <p className="text-gray-700">{customer.gender || "N/A"}</p>
              </div>
            </div>
            <div className="flex items-center">
              <Phone className="text-red-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Phone</h3>
                <p className="text-gray-700">{customer.phone || "N/A"}</p>
              </div>
            </div>
            <div className="flex items-center">
              <MailIcon className="text-yellow-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Email</h3>
                <p className="text-gray-700">
                  {customer.primary_email || "N/A"}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Globe className="text-indigo-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Country of Residence</h3>
                <p className="text-gray-700">
                  {customer.country_of_residence || "N/A"}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <CreditCard className="text-gray-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Total Spent</h3>
                <p className="text-gray-700">{displayedTotalSpent}</p>
              </div>
            </div>
            <div className="flex items-center">
              <User className="text-purple-500 mr-3" />
              <div>
                <h3 className="text-lg font-semibold">Customer No</h3>
                <p className="text-gray-700">{displayedCustomerNo}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default CustomerDetail;
