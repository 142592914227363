import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { ChevronUp, ChevronDown } from "react-feather";
import { Link } from "react-router-dom";

// Determine the loyalty tier based on total_paid
const getTier = (totalPaid) => {
  if (totalPaid > 20000000) return "Platinum";
  if (totalPaid > 10000000) return "Gold";
  if (totalPaid > 5000000) return "Silver";
  if (totalPaid > 1000000) return "Bronze";
  return "Sapphire";
};

// Display the tier label with appropriate styling
const TierLabel = ({ tier }) => {
  let colorClass;
  switch (tier) {
    case "Platinum":
      colorClass = "bg-gray-300 text-white";
      break;
    case "Gold":
      colorClass = "bg-yellow-500 text-white";
      break;
    case "Silver":
      colorClass = "bg-gray-500 text-white";
      break;
    case "Bronze":
      colorClass = "bg-orange-700 text-white";
      break;
    case "Sapphire":
      colorClass = "bg-indigo-500 text-white";
      break;
    default:
      colorClass = "bg-gray-300 text-black";
  }

  return (
    <span
      className={`px-3 py-1 rounded-full text-xs font-semibold ${colorClass}`}
    >
      {tier}
    </span>
  );
};

const ViewCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [countryFilter, setCountryFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  const [selectedTier, setSelectedTier] = useState("");

  // Fetch customer data from the API
  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/loyalty`
      );
      const { customers } = response.data;
      setCustomers(customers);
      setFilteredCustomers(customers);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setError("Failed to fetch customers.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    filterCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryFilter,
    genderFilter,
    searchQuery,
    customers,
    sortConfig,
    selectedTier,
  ]);

  // Handle page changes
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages()) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate total pages
  const totalPages = () => Math.ceil(filteredCustomers.length / itemsPerPage);

  // Handle items per page changes
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  // Handle sorting
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Filter customers based on criteria
  const filterCustomers = () => {
    let filteredData = customers.filter((customer) => {
      const matchesCountry = countryFilter
        ? customer.country_of_residence &&
          customer.country_of_residence.toUpperCase() === countryFilter
        : true;
      const matchesGender = genderFilter
        ? customer.gender === genderFilter
        : true;
      const matchesSearch = searchQuery
        ? customer.customer_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        : true;

      // If a tier is selected, filter by that tier
      const customerTier = getTier(customer.total_paid);
      const matchesTier = selectedTier ? customerTier === selectedTier : true;

      return matchesCountry && matchesGender && matchesSearch && matchesTier;
    });

    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (aValue === undefined || aValue === null) aValue = "";
        if (bValue === undefined || bValue === null) bValue = "";

        if (sortConfig.key === "total_paid") {
          aValue = Number(aValue);
          bValue = Number(bValue);
        } else {
          aValue = aValue.toString().toLowerCase();
          bValue = bValue.toString().toLowerCase();
        }

        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredCustomers(filteredData);
    setCurrentPage(1);
  };

  // Render pagination
  const renderPagination = () => {
    const pageNumbers = [];
    const delta = 2;
    const total = totalPages();
    const rangeStart = Math.max(2, currentPage - delta);
    const rangeEnd = Math.min(total - 1, currentPage + delta);

    pageNumbers.push(
      <button
        key={1}
        className={`join-item btn ${
          currentPage === 1 ? "btn-active" : "btn-outline"
        }`}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    );

    if (rangeStart > 2) {
      pageNumbers.push(
        <button key="left-ellipsis" className="join-item btn btn-disabled">
          ...
        </button>
      );
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`join-item btn ${
            currentPage === i ? "btn-active" : "btn-outline"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (rangeEnd < total - 1) {
      pageNumbers.push(
        <button key="right-ellipsis" className="join-item btn btn-disabled">
          ...
        </button>
      );
    }

    if (total > 1) {
      pageNumbers.push(
        <button
          key={total}
          className={`join-item btn ${
            currentPage === total ? "btn-active" : "btn-outline"
          }`}
          onClick={() => handlePageChange(total)}
        >
          {total}
        </button>
      );
    }

    return pageNumbers;
  };

  // Get the sort icon
  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <ChevronUp className="ml-1" size={16} />
    ) : (
      <ChevronDown className="ml-1" size={16} />
    );
  };

  // Unique countries
  const uniqueCountries = useMemo(
    () => [
      ...new Set(
        customers
          .map(
            (c) =>
              c.country_of_residence && c.country_of_residence.toUpperCase()
          )
          .filter(Boolean)
      ),
    ],
    [customers]
  );

  // Unique genders
  const uniqueGenders = useMemo(
    () => [
      ...new Set(customers.map((c) => c.gender).filter((g) => g !== "N/A")),
    ],
    [customers]
  );

  // Current page customers
  const currentCustomers = useMemo(
    () =>
      filteredCustomers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [filteredCustomers, currentPage, itemsPerPage]
  );

  const handleTierClick = (tier) => {
    if (selectedTier === tier) {
      // If clicking the same tier again, clear the filter
      setSelectedTier("");
    } else {
      setSelectedTier(tier);
    }
  };

  const tiersData = [
    { tier: "Platinum", range: "> 20m KES" },
    { tier: "Gold", range: "10m - 20m KES" },
    { tier: "Silver", range: "5m - 10m KES" },
    { tier: "Bronze", range: "1m - 5m KES" },
    { tier: "Sapphire", range: "< 1m KES" },
  ];

  return (
    <Sidebar>
      <div className="flex flex-col items-center justify-start min-h-screen p-4 sm:p-6 md:p-8 bg-gray-100">
        <div className="w-full max-w-7xl">
          {/* Filters Section */}
          <div className="bg-white shadow-md rounded-lg p-4 mb-6">
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                className="input input-bordered w-full sm:w-1/3"
                placeholder="Search by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <select
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
                className="select select-bordered w-full sm:w-1/3"
              >
                <option value="">Filter by Country</option>
                {uniqueCountries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <select
                value={genderFilter}
                onChange={(e) => setGenderFilter(e.target.value)}
                className="select select-bordered w-full sm:w-1/3"
              >
                <option value="">Filter by Gender</option>
                {uniqueGenders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Legend Section */}
          <div className="bg-white shadow-md rounded-lg p-4 mb-6">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
              <h2 className="text-lg font-bold text-gray-800">Loyalty Tiers</h2>
              {selectedTier && (
                <button
                  className="btn btn-sm btn-outline"
                  onClick={() => setSelectedTier("")}
                >
                  Clear Tier Filter (Currently: {selectedTier})
                </button>
              )}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-4">
              {tiersData.map(({ tier, range }) => (
                <div
                  key={tier}
                  onClick={() => handleTierClick(tier)}
                  className={`flex flex-col items-center justify-center p-3 rounded-lg cursor-pointer border 
                  hover:shadow-md transition 
                  ${
                    selectedTier === tier
                      ? "bg-blue-50 border-blue-300"
                      : "bg-white border-gray-200"
                  }`}
                >
                  <TierLabel tier={tier} />
                  <span className="mt-2 text-xs text-gray-700 font-medium">
                    {range}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Loading / Error / Table */}
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <TailSpin height="80" width="80" color="#4fa94d" visible />
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : (
            <div className="bg-white shadow-md rounded-lg p-4">
              <div className="overflow-x-auto">
                <table className="min-w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2 text-left">#</th>
                      <th
                        className="px-4 py-2 cursor-pointer"
                        onClick={() => handleSort("customer_name")}
                      >
                        <div className="flex items-center">
                          Name {getSortIcon("customer_name")}
                        </div>
                      </th>
                      <th
                        className="px-4 py-2 cursor-pointer"
                        onClick={() => handleSort("gender")}
                      >
                        <div className="flex items-center">
                          Gender {getSortIcon("gender")}
                        </div>
                      </th>
                      <th className="px-4 py-2 text-left">Phone</th>
                      <th className="px-4 py-2 text-left">Primary Email</th>
                      <th
                        className="px-4 py-2 cursor-pointer"
                        onClick={() => handleSort("country_of_residence")}
                      >
                        <div className="flex items-center">
                          Country of Residence{" "}
                          {getSortIcon("country_of_residence")}
                        </div>
                      </th>
                      <th
                        className="px-4 py-2 cursor-pointer"
                        onClick={() => handleSort("total_paid")}
                      >
                        <div className="flex items-center">
                          Tier {getSortIcon("total_paid")}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCustomers.length > 0 ? (
                      currentCustomers.map((customer, index) => {
                        const tier = getTier(customer.total_paid);
                        return (
                          <tr
                            key={customer.customer_no}
                            className="hover:bg-gray-100"
                          >
                            <td className="border px-4 py-2">
                              {(currentPage - 1) * itemsPerPage + index + 1}
                            </td>
                            <td className="border px-4 py-2">
                              <Link
                                to={`/view-customers/${customer.customer_no}`}
                                className="text-blue-500 hover:underline"
                              >
                                {customer.customer_name}
                              </Link>
                            </td>
                            <td className="border px-4 py-2">
                              {customer.gender || "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {customer.phone || "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {customer.primary_email || "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              {customer.country_of_residence || "N/A"}
                            </td>
                            <td className="border px-4 py-2">
                              <TierLabel tier={tier} />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="text-center px-4 py-2 text-gray-500"
                        >
                          No customers found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination and Items Per Page */}
              <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
                <div className="flex items-center mb-2 sm:mb-0">
                  <label className="mr-2 font-medium">Items per page:</label>
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="select select-bordered select-sm"
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <div className="flex items-center space-x-1">
                  <button
                    className="btn btn-sm"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    «
                  </button>
                  {renderPagination()}
                  <button
                    className="btn btn-sm"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages()}
                  >
                    »
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default ViewCustomers;
